import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchProcessingFormStore = defineStore('searchProcessingFormStore', {
  state: () => ({
    ProcessingForm_id: null as number | null,
    ProcessingForm_ident: null as number | null,
    ProcessingForm_statusIdMin: null as number | null,
    ProcessingForm_statusIdMax: null as number | null,
    ProcessingForm_isCorrection: null as string | null,
    ProcessingForm_validFrom: null as string | null,
    ProcessingForm_validUntil: null as string | null,
    ProcessingForm_periodId: null as number | null,
    ProcessingForm_code: null as string | null,
    ProcessingForm_milkLabel: null as string | null,
    ProcessingForm_correction: null as number | null,
    items: [
      { key: 'ProcessingForm_id', operation: ':' },
      { key: 'ProcessingForm_ident', operation: ':' },
      { key: 'ProcessingForm_statusIdMin', operation: '>=', itemType: 'number' },
      { key: 'ProcessingForm_statusIdMax', operation: '<=', itemType: 'number' },
      { key: 'ProcessingForm_isCorrection', operation: ':', itemType: 'number' },
      { key: 'ProcessingForm_validFrom', operation: '>=' },
      { key: 'ProcessingForm_validUntil', operation: '<=' },
      { key: 'ProcessingForm_periodId', operation: ':' },
      { key: 'ProcessingForm_code', operation: ':' },
      { key: 'ProcessingForm_milkLabel', operation: ':' },
      { key: 'ProcessingForm_correction', operation: ':' }
    ] as TermItemDefinition[]
  })
})
